import React from "react";
const PrivacyPolicy = () => {
    return (
        <>
            <section className="Policy">
                <div className="container">
                    <h1 className="page-title">Goodbusiness</h1>
                    <p className="text-end">Date reviewed: January 2024</p>
                    <h1>DATA PROTECTION POLICY - ANTHOS CARBON TRACKER TOOL</h1>
                    <div className="policy-data">
                        <h2>1. INTRODUCTION</h2>
                        <p>
                            Good Business needs to gather and use certain information for the <b><u>Anthos Carbon Tracker Tool</u></b>
                        </p>

                        <p>
                            Good Business is committed to being transparent about how it
                            collects and uses the personal data of its workforce, and to
                            meeting its data protection obligations. This policy sets out our
                            commitment to data protection, and individual rights and
                            obligations in relation to personal data.
                        </p>
                        <p>
                            It describes how this personal data must be collected, handled and
                            stored to meet our data protection standards — and to comply with
                            the law.
                        </p>

                        <h2>2. POLICY SCOPE</h2>
                        <p>
                            This policy applies to all of our employees, as well as any
                            contractors, suppliers and other people who work on our behalf
                        </p>
                        <p>
                            It applies to all data that the company holds relating to
                            identifiable individuals, including:
                        </p>
                        <ul>
                            <li>Contact information (name,email).</li>
                            <li>Country of residence and holiday homes.</li>
                            <li>Household composition.</li>
                            <li>
                                General data concerning their homes,travels,food and shopping habits.
                            </li>
                            <li>
                                Clients can provide percentages and types of outside investments.
                            </li>
                        </ul>

                        <h2>3. DEFINITIONS</h2>
                        <p>
                            <span>"Personal data" </span> is any information that relates to an
                            individual who can be identified from that information. Processing
                            is any use that is made of data, including collecting, storing,
                            amending, disclosing or destroying it.
                        </p>
                        <p>
                            <span>"Special categories of Personal Data": </span> Personal data revealing racial or ethnic origin, political
                            opinions, religious or philosophical beliefs, or trade union membership, and genetic data, biometric
                            data for the purpose of uniquely identifying a natural person, data concerning health or data
                            concerning a natural person's sex life or sexual orientation within the meaning of Article 9 of the
                            GDPR. This also includes personal data relating to criminal convictions and offences within the
                            meaning of Article 10 of the GDPR and national identification numbers.
                        </p>
                        <p>
                            <span>"GDPR”": </span>  Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016
                            on the protection of natural persons with regard to the processing of personal data and on the free
                            movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).
                        </p>

                        <h2>4. DATA PROTECTION LAW AND PRINCIPLES</h2>
                        <p>
                            The General Data Protection Regulation (“GDPR”) describes how
                            organisations must collect, handle and store personal information.
                        </p>
                        <p>
                            These rules apply regardless of whether data is stored
                            electronically, on paper or on other materials
                        </p>
                        <p>
                            Good Business processes personal data in accordance with the
                            following data protection principles set out in the GDPR:
                        </p>
                        <ul>
                            <li>
                                Processing personal data lawfully, fairly and in a transparent
                                manner{" "}
                            </li>
                            <li>
                                Collecting personal data only for specified, explicit and
                                legitimate purposes.
                            </li>
                            <li>
                                Processing personal data only where it is adequate, relevant and
                                limited to what is necessary for the purposes of processing.{" "}
                            </li>
                            <li>
                                Keeping accurate personal data and taking all reasonable steps
                                to ensure that inaccurate personal data is rectified or deleted
                                without delay.{" "}
                            </li>
                            <li>
                                Keeping personal data only for the period necessary for
                                processing.
                            </li>
                            <li>
                                Adopting appropriate measures to make sure that personal data is
                                secure, and protected against unauthorised or unlawful
                                processing, and accidental loss, destruction or damage.
                            </li>
                        </ul>

                        <p>
                            Good Business tells individuals the reasons for processing their
                            personal data, how it uses such data and the legal basis for
                            processing in its privacy notices. It will not process personal
                            data of individuals for other reasons.
                        </p>
                        <p>
                            Where Good Business processes special categories of personal data
                            this is done in accordance with a policy
                            on special categories of data.
                        </p>

                        <p>
                            Good Business keeps a record of its processing activities in
                            respect of personal data in accordance with the requirements of
                            the GDPR.{" "}
                        </p>
                        <h2>5. DATA PROCESSING OPERATIONS</h2>
                        <p><b>Description of the nature of the Data Processing Operation</b></p>
                        <ul>
                            <li>Collection, recording, organisation, analysis and reporting on data associated with personal
                                carbon footprints and consultation with data subjects on this issue</li>
                            <li>Due to the SK Carbon Pledge, the individual SK client data will be used to create the yearly
                                SK Carbon Pledge group report </li>
                        </ul>
                        <p><b>Purposes of the Data Processing Operation</b></p>
                        <ul>
                            <li>To allow Good Business to calculate individual carbon footprints, communicate with the
                                Data Subjects and provide advice and guidance and to report to Anthos on the individual
                                and aggregated results of this analysis as part of a contract to deliver these services to
                                Anthos. </li>
                        </ul>
                        <p><b>Categories of Data Subjects</b></p>
                        <ul>
                            <li>SKs.</li>
                            <li>Family members.</li>
                            <li>Anthos employees.</li>
                        </ul>
                        <p><b>(Categories of) Personal Data</b></p>
                        <ul>
                            <li>Contact information (name, email)</li>
                            <li>Country of residence and holiday homes.</li>
                            <li>Household composition.</li>
                            <li>General data concerning their homes, travels, food and shopping habits.</li>
                            <li>Clients can provide percentages and types of outside investments.</li>
                        </ul>
                        <p><b>Retention period for the Personal Data, or the criteria used to establish the retention period</b></p>
                        <ul>
                            <li>We retain the information we collect no longer than is reasonably necessary to fulfil the
                                purposes that such data was originally collected in accordance with our internal data
                                retention polices or to comply with our legal and regulatory obligations. Data collected will
                                be held for as long as is necessary to allow us to make comparisons over time and to assess
                                trends, both at an aggregated level and an individual level</li>

                        </ul>
                        <h2>6. INDIVIDUAL RIGHTS</h2>
                        <p>
                            Those about whom we hold and process personal data, including
                            employees, have a number of rights in relation to their personal
                            data.
                        </p>
                        <p>
                            <span>Subject access requests</span>
                        </p>
                        <p>
                            Individuals have the right to make a subject access request. If
                            you make a subject access request, Good Business will tell you:
                        </p>
                        <ul>
                            <li>
                                whether or not your data is processed and if so why, the
                                categories of personal data concerned and the source of the data
                                if it is not collected from you;{" "}
                            </li>
                            <li>to whom your data is or may be disclosed;</li>
                            <li>for how long your personal data is stored; </li>
                            <li>
                                your rights to rectification or erasure of data, or to restrict
                                or object to processing;{" "}
                            </li>
                            <li>
                                your right to complain to the Information Commissioner if you
                                think Good Business has failed to comply with your data
                                protection rights; and
                            </li>
                            <li>
                                whether or not Good Business carries out automated
                                decision-making and the logic involved in any such
                                decision-making.
                            </li>
                        </ul>
                        <p>
                            We will also provide you with a copy of the personal data
                            undergoing processing.
                        </p>

                        <p>
                            To make a subject access request, you should send the request to{" "}
                            <a href="mailto:david@good.business">david@good.business</a>. We will usually ask for proof
                            of identification before the request can be processed.
                        </p>

                        <p>
                            We will normally respond to a request within a period of two weeks
                            from the date it is received, and if this is not possible (for
                            example if the request is particularly complex) we will notify
                            you.
                        </p>

                        <p>
                            If a subject access request is manifestly unfounded or excessive,
                            for example is a repeat of a previous request, we are not obliged
                            to comply with it. Alternatively, we can agree to respond but will
                            charge a fee, which will be based on the administrative cost of
                            responding to the request.{" "}
                        </p>

                        <p><b>Other rights</b></p>

                        <p>
                            You have a number of other rights in relation to your personal
                            data. You can require Good Business to:
                        </p>
                        <ul>
                            <li>rectify inaccurate data;</li>
                            <li>
                                stop processing or erase data that is no longer necessary for
                                the purposes of processing;
                            </li>

                            <li>
                                stop processing or erase data if your interests override our
                                legitimate grounds for processing data (where we rely on
                                legitimate interests as a reason for processing data);
                            </li>

                            <li>
                                stop processing or erase data if processing is unlawful; and
                            </li>

                            <li>
                                stop processing data for a period if data is inaccurate or if
                                there is a dispute about whether or not the individual's
                                interests override our legitimate grounds for processing data.
                            </li>
                        </ul>
                        <p>
                            To ask Good Business to take any of these steps, you should send
                            the request to
                            <a href="mailto:david@good.business"> david@good.business</a>.
                        </p>

                        <p>
                            <span>Disclosing data for other reasons</span>
                        </p>

                        <p>
                            In certain circumstances, the law allows personal data to be
                            disclosed to law enforcement agencies without the consent of the
                            data subject.
                        </p>

                        <p>
                            Under these circumstances we will disclose requested data.
                            However, the data controller will ensure the request is
                            legitimate, seeking assistance from the board and from the
                            company’s legal advisers where necessary.
                        </p>

                        <h2>6. INTERNATIONAL DATA TRANSFERS</h2>
                        <p>
                            Good Business will not transfer personal data to countries outside
                            the EEA.
                        </p>

                        <h2>7. RESPONSIBILITIES</h2>
                        <p>
                            Everyone who works for or with us has some responsibility for
                            ensuring data is collected, stored and handled appropriately.
                        </p>



                        <p>
                            <span>Specific responsibilities</span>
                        </p>

                        <p>
                            In addition to the individual responsibilities set out above, the
                            following people have key areas of responsibility in respect of
                            data protection:
                        </p>

                        <ul>
                            <li>
                                Giles Gibbons, as our CEO, is ultimately responsible for
                                ensuring that we meet our legalobligations.
                            </li>
                            <li>The Managing Partner, David Lourie is responsible for:</li>
                            <ul className="inner-list">
                                <li>
                                    Keeping the board of directors updated about data protection
                                    responsibilities, risks and issues.
                                </li>
                                <li>
                                    Reviewing all data protection procedures and related policies,
                                    in line with an agreed schedule.
                                </li>
                                <li>
                                    Arranging data protection training and advice for the people
                                    covered by this policy.
                                </li>
                                <li>
                                    Handling data protection questions from staff and anyone else
                                    covered by this policy.
                                </li>
                                <li>
                                    Dealing with requests from individuals to see the data we hold
                                    about them (also called ‘subject access requests’).
                                </li>
                                <li>
                                    Checking and approving any contracts or agreements with third
                                    parties that may handle the company’s sensitive data.{" "}
                                </li>
                                <li>
                                    Approving any data protection statements attached to
                                    communications such as emails and letters.
                                </li>
                                <li>
                                    Addressing any data protection queries from journalists or
                                    media outlets like newspapers.
                                </li>
                                <li>
                                    Where necessary, working with other staff to ensure marketing
                                    initiatives abide by data protection principles.
                                </li>
                            </ul>
                        </ul>


                        <ul>
                            <li>Fluid IT  (our IT partner) are responsible for (in relation to all Good Business IT provision):</li>
                            <ul className="inner-list">
                                <li>
                                    Ensuring all systems, services and equipment used for storing
                                    data meet acceptable security standards.
                                </li>
                                <li>
                                    Performing regular checks and scans to ensure security
                                    hardware and software is functioning properly.
                                </li>
                                <li>
                                    Evaluating any third-party services the company is considering
                                    using to store or process data. For instance, cloud computing
                                    services.
                                </li>
                            </ul>
                        </ul>
                        <ul>
                            <li>Creative Folks are responsible for (in relation to all Anthos Carbon Tracker Tool hosting provision):</li>
                            <ul className="inner-list">
                                <li>
                                    Ensuring all systems, services and equipment used for storing
                                    data meet acceptable security standards.
                                </li>
                                <li>
                                    Performing regular checks and scans to ensure security
                                    hardware and software is functioning properly.
                                </li>
                                <li>
                                    Evaluating any third-party services the company is considering
                                    using to store or process data. For instance, cloud computing
                                    services.
                                </li>
                            </ul>
                        </ul>

                        <h2>8. DATA SECURITY</h2>
                        <p>
                            Good Business takes the security of personal data seriously. We
                            have internal policies and controls in place (set out below) to
                            protect personal data against loss, accidental destruction, misuse
                            or disclosure, and to ensure that data is not accessed, except by
                            employees in the proper performance of their duties.
                        </p>

                        <p>
                            Where we engage third parties to process personal data on our
                            behalf, such parties do so on the basis of written instructions,
                            are under a duty of confidentiality and are obliged to implement
                            appropriate technical and organisational measures to ensure the
                            security of data.
                        </p>

                        <p>
                            If Good Business discovers that there has been a breach of
                            personal data that poses a risk to the rights and freedoms of
                            individuals, we will report it to the Information Commissioner
                            within 72 hours of discovery. We will record all data breaches
                            regardless of their effect.
                        </p>
                        <br />
                        <br />
                        <p className="text-center">25 Gerrard Street, London, W1D 6JL | +44 (0)20 7494 0565 | <a href="https://www.good.business" target="_blank">www.good.business</a></p>
                        <p className="text-center">Registered Company Number 356 1306</p>


                    </div>
                </div>
            </section>
        </>
    );
};
export default PrivacyPolicy;
