import React, { useState } from "react";
import ChangePassword from "../../components/ChangePassword";

const AdminChangePassword = () => {
  
  return (
    <>
      <ChangePassword isAdmin={true} />
    </>
  );
};
export default AdminChangePassword;
