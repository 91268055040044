import React from "react";


const Signup = () => {

    return (
        <>
            <h1>Signup Works</h1>
        </>
    )

}

export default Signup;