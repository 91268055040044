import React, { useEffect, useState } from "react";
import generalImg from "../../assets/images/user.svg";
import houseImg from "../../assets/images/t_house.svg";
import foodImg from "../../assets/images/food.svg";
import carImg from "../../assets/images/t_car.svg";
import financialImg from "../../assets/images/financial .svg";
import { Link, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { homeformIds } from "../../redux-store/actions/user";
import { setCurrentHomeId } from "../../redux-store/reducers/user";


const FormTabsView = ({ activeTab, handleActiveTab, setSelectedHome, homeLength }) => {
  const dispatch = useDispatch()
  // const [activeTab, setActiveTab] = useState(activeTab);
  const [homeActiveTab, setHomeActiveTab] = useState(1);
  const [homeCount, setHomeCount] = useState(homeLength || 1);

  const user = useSelector((state) => state.auth);
  const homeIds = useSelector((state) => state.users?.homeFormIdList) || [];

  const general_information_id = user?.generalInfoId;

  useEffect(() => {
    if(general_information_id){
      dispatch(homeformIds(general_information_id))
    }

  }, [general_information_id])

  const handleActiveTabFunc = (active) => {
    handleActiveTab(active)
  }

  useEffect(() => {
    if (homeLength > 0) {
      setHomeCount(homeLength)
    }
  }, [homeIds]);

  const handleHomeTabs = (activeIndex) => {
    setHomeActiveTab(activeIndex);
    setSelectedHome(activeIndex)
    if (homeLength > 0) {
      dispatch(setCurrentHomeId(homeIds[activeIndex]))
    }
  }

  const renderHomes = () => {
    const homes = [];
    for (let i = 1; i <= homeCount; i++) {
      homes.push(
        <li key={i} className={i === homeActiveTab ? 'active' : ''} onClick={() => handleHomeTabs(i)}>
          <a>Home {i}</a>
        </li>
      );
    }
    return homes;
  };

  return (
    <section className="information mt-80 mb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="information-header">
              <div className="col-div">
                <div className="information-icon-box">

                  <div
                    className={`information-cricle-box ${activeTab === "general" ? "active" : ""
                      }
                         
                        `}
                    onClick={() => handleActiveTabFunc("general")}
                  >
                    <img src={generalImg} alt="" />
                  </div>
                  <p onClick={() => handleActiveTabFunc("general")}>General Information</p>
                </div>
              </div>
              <div className="col-div">
                <div className="information-icon-box">
                  <div
                    className={`information-cricle-box ${activeTab === "home" ? "active" : ""
                      } 
                        `}
                    onClick={() => handleActiveTabFunc("home")}
                  >
                    <img src={houseImg} alt="" />
                  </div>
                  <p onClick={() => handleActiveTabFunc("home")}>Your Home</p>
                </div>
              </div>
              <div className="col-div">
                <div className="information-icon-box">
                  <div
                    className={`information-cricle-box ${activeTab === "travel" ? "active" : ""
                      }
                        `}
                    onClick={() => handleActiveTabFunc("travel")}
                  >
                    <img src={carImg} alt="" />
                  </div>
                  <p onClick={() => handleActiveTabFunc("travel")}>Travel</p>
                </div>
              </div>
              <div className="col-div">
                <div className="information-icon-box">
                  <div
                    className={`information-cricle-box ${activeTab === "food" ? "active" : ""
                      }
                        `}
                    onClick={() => handleActiveTabFunc("food")}
                  >
                    <img src={foodImg} alt="" />
                  </div>
                  <p onClick={() => handleActiveTabFunc("food")}>Food and Shopping</p>
                </div>
              </div>
              <div className="col-div">
                <div className="information-icon-box">
                  <div
                    className={`information-cricle-box ${activeTab === "financial" ? "active" : ""
                      }  `}
                    onClick={() => handleActiveTabFunc("financial")}
                  >
                    <img src={financialImg} alt="" />
                  </div>
                  <p onClick={() => handleActiveTabFunc("financial")}>Financial assets</p>
                </div>
              </div>
            </div>
            {activeTab === "home" && (
              <div className="information-header-nav">
                <ul>
                  {renderHomes()}
                </ul>
              </div>
            )}
          </div>

        </div>
      </div>
    </section>
  );
};

export default FormTabsView;
