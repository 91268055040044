import React, { useState } from "react";
import ChangePassword from "../components/ChangePassword";

const UserChangePassword = () => {
  return (
    <>
      <ChangePassword isAdmin={false} />
    </>
  );
};
export default UserChangePassword;
